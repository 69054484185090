import {GeoGebraAPI, ClientEvent, Archive} from "../lib/geogebra";
import {
  AckState,
  CalculatorState,
  ChangeEvent,
  WorkEvent,
} from "../lib/types";
import {GGBMultiplayer} from "./multiplayer";
import log from "./console-logger";

export class WorkEventBus {
  embedLabel: string | undefined;
  parent: GGBMultiplayer;
  api: GeoGebraAPI;
  activePage: string;

  expectedAcknowledgements: Set<WorkEvent> = new Set<WorkEvent>();

  // used to stop event dispatching when doing some work internally through the API (e.g. renaming)
  blockOutgoingEvents = false;
  // used to stop event dispatching when the user is creating multiple objects at once (e.g. regular polygon)
  batchProcessing = false;
  // used to stop event dispatching while a paste is happening
  pasting = false;
  renameListener?: (from: string, to: string) => void;
  addListener?: (name: string) => void;
  editListener?: (name: string) => void;
  updateListener?: (name: string) => void;
  removeListener?: (name: string) => void;
  clientListener?: (evt: ClientEvent) => void;

  constructor(parent:GGBMultiplayer, api: GeoGebraAPI, embedLabel: string | undefined) {
    this.parent = parent;
    this.api = api;
    this.embedLabel = embedLabel;
    this.activePage = api.getActivePage();
  }

  setupOutgoingEventBus(): void {
    const api = this.api;
    let debounce: number | null = null;
    const pendingUpdates: string[] = [];

    const sendUpdate = (label:string) => {
      const value = this.getValue(label);
      this.send({
        eventType: "obj_change",
        label: label,
        property: value ? "value" : "style",
        content: value ? value : api.getXML(label),
      });
    };

    const sendUpdates = () => {
      pendingUpdates.forEach(sendUpdate);
      pendingUpdates.splice(0, pendingUpdates.length);
      debounce = null;
    };

    api.registerUpdateListener(this.updateListener = (label) => {
      if (this.blockOutgoingEvents || this.pasting) {
        return;
      }
      if ((api.hasUnlabeledPredecessors(label) || api.isMoveable(label)) && !this.api.isAnimating(label)) {
        if (!debounce) {
          debounce = window.setTimeout(sendUpdates, 300);
        }
        if (!pendingUpdates.includes(label)) {
          pendingUpdates.push(label);
        }
      }
    });

    api.registerRenameListener(this.renameListener = (from, to) => {
      if (this.blockOutgoingEvents || this.pasting) {
        return;
      }
      this.send({eventType: "rename", label: from, newLabel: to});
    });

    this.addListener = (label:string) => {
      if (this.blockOutgoingEvents || this.batchProcessing || this.pasting) {
        return;
      }

      this.transmitImageData(label);

      this.send({
        eventType: "obj_create",
        labels: [label],
        content: this.getProps(label),
      });
    };

    api.registerAddListener(this.addListener);

    api.registerRemoveListener(this.removeListener = (label) => {
      if (this.blockOutgoingEvents) {
        return;
      }
      this.deleteEmbedEventBus(label);
      this.send({eventType: "obj_delete", label: label});
    });

    api.registerClientListener(this.clientListener = (evt) => {
      if (evt.type == "embedLoaded" && evt.api) {
        this.parent.initEventBus(evt.target, evt.api, !!evt.loadedWithFile);
        return;
      }
      if (evt.type == "loadPage") {
        const remaining: ChangeEvent[] = [];
        for (const oldEvent of this.parent.storedState?.events || []) {
          if (oldEvent.page == this.activePage) {
            this.checkAndHandleChangeEvent(oldEvent, false);
          } else {
            remaining.push(oldEvent);
          }
        }
        if (this.parent.storedState) {
          this.parent.storedState.events = remaining;
        }
        this.blockOutgoingEvents = false;
        return;
      }
      if (evt.type == "selectPage") {
        this.activePage = evt.argument;
        log.debug("Active page " + this.activePage);
        this.blockOutgoingEvents = true;
        return;
      }
      if (this.blockOutgoingEvents || evt.type == "viewChanged2D") {
        return;
      }

      switch (evt.type) {
      case "updateStyle":
        if (!this.pasting) {
          this.send({eventType: "obj_change",
            label: evt.target, property: "style",
            content: api.getStyleXML(evt.target)});
        }
        break;

      case "redefine":
        sendUpdate(evt.target);
        break;

      case "startAnimation":
        this.send({eventType: "setting_change", properties: {animating: true}});
        break;

      case "stopAnimation":
        this.send({eventType: "setting_change", properties: {animating: false}});
        break;

      case "select":
        this.send({eventType: "obj_change", label: evt.target, property: "selection"});
        break;

      case "deselect":
        this.send({eventType: "obj_change", property: "selection"});
        break;

      case "switchCalculator":
        this.send({eventType: "setting_change", properties: {calculator: evt.argument}});
        break;

      case "pasteElms":
        this.pasting = true;
        break;

      case "pasteElmsComplete":
        this.pasting = false;
        if (evt.targets) {
          this.send({
            eventType: "obj_create",
            labels: evt.targets,
            content: evt.targets.map((obj) => this.getProps(obj)).join(""),
          });
        }
        break;

      case "batchAddStarted":
        this.batchProcessing = true;
        break;

      case "batchAddComplete":
        this.batchProcessing = false;
        if (!this.pasting) {
          this.send({
            eventType: "obj_create",
            labels: api.getSiblingObjectNames(evt.target),
            content: this.getProps(evt.target),
          });
        }
        break;

      case "groupObjects":
      case "ungroupObjects":
        this.send({eventType: "group", labels: evt.targets || [], grouped: evt.type == "groupObjects"});
        break;

      case "viewPropertiesChanged": {
        const viewId = Number.parseInt(evt.argument);
        const graphicsOptions = api.getGraphicsOptions(viewId);

        this.send({eventType: "graphics_change", viewId, graphicsOptions});
        break;
      }

      case "orderingChange":
        this.send({
          eventType: "order_change",
          order: evt.argument,
        });
        break;
      case "addPage":
        this.activePage = evt.argument;
        this.send({
          eventType: "add_page",
          page: evt.argument,
        });
        break;
      case "pastePage":
        this.activePage = evt.argument;
        this.blockOutgoingEvents = true;
        const file: Archive = evt.ggbFile ? JSON.parse(evt.ggbFile) : null;
        const xml = evt.ggbFile ? file.archive.find((f) => f.fileName == "geogebra.xml")?.fileContent : "";
        this.send({
          eventType: "paste_page",
          page: evt.argument,
          xml: xml || "",
          to: evt.to || -1,
          labels: evt.targets || [],
        });
        break;
      case "removePage":
        this.send({
          eventType: "remove_page",
          page: evt.argument,
        });
        break;
      case "movePage":
        this.send({
          eventType: "move_page",
          page: evt.argument,
          to: evt.to || -1,
        });
        break;
      case "clearPage":
        this.send({
          eventType: "clear_page",
          page: evt.argument,
        });
        break;
      case "renamePage":
        this.send({
          eventType: "rename_page",
          page: evt.argument,
          title: evt.title || "",
        });
        break;
      }
    });
  }

  deleteEmbedEventBus(label: string) {
    const index = this.parent.eventBuses.findIndex((bus) => bus.embedLabel == label);
    if ((this.api.getEmbeddedCalculators(false) || {})[label] && index > -1) {
      this.parent.eventBuses.splice(index, 1);
    }
  }

  getProps(label: string): string {
    return this.api.getAlgorithmXML(label) || this.api.getXML(label);
  }

  getValue(label: string): string | undefined {
    const commandString = this.api.getCommandString(label, false);
    if (commandString) {
      if (this.api.isMoveable(label) && this.api.getObjectType(label) == "point") {
        const valueString = this.api.getValueString(label, false);
        // point on path or in region: send value instead of definition
        return `SetValue(${valueString.replace("=", ",")})`;
      }
      return label + ":" + commandString;
    }
    const valueString = this.api.getValueString(label, false);
    if (valueString && valueString != label) {
      return valueString;
    }
  }

  transmitImageData(label: string) {
    const imageName = this.api.getImageFileName(label);
    if (imageName) {
      const json = this.api.getFileJSON(false);
      const imageFile = json.archive.find((item) => item.fileName.includes(imageName));
      if (imageFile) {
        this.send({eventType: "image_add", fileName: imageName, fileContent: imageFile.fileContent});
      }
    }
  }

  checkAndHandleChangeEvent(evt: ChangeEvent, showUserMarkers = true) {
    // checking if we really receive all events and receive them in order
    if (this.parent.globalEventCounter + 1 != evt.globalEventCounter) {
      log.error(`Event order inconsistency detected, local counter: ${this.parent.globalEventCounter}, `+
        `event counter: ${evt.globalEventCounter}`, evt);
    }
    this.parent.globalEventCounter = evt.globalEventCounter;
    if (evt.embedLabel == this.embedLabel && (evt.page == this.activePage || evt.eventType.includes("page"))) {
      log.debug("Change event received:", evt);

      this.blockOutgoingEvents = true;
      this.api.setErrorDialogsActive(false);

      this.handleChangeEvent(this.api, evt, showUserMarkers);

      this.api.setErrorDialogsActive(true);
      this.blockOutgoingEvents = false;
      return true;
    } else {
      return false;
    }
  }

  handleChangeEvent(api: GeoGebraAPI, evt: ChangeEvent, showUserMarkers: boolean) {
    if (evt.eventType == "content_change") {
      api.setXML(evt.xml);
    }

    if (evt.eventType == "obj_create" && evt.labels) {
      evt.labels.filter((label) => this.api.exists(label)).forEach((label) => {
        const baseLabel = label.split("_")[0];
        let newLabel;
        let counter = 0;
        do {
          newLabel = baseLabel + "_{" + (counter++) + "}";
        } while (api.exists(newLabel));
        api.renameObject(label, newLabel);
        this.send({
          eventType: "obj_create",
          labels: [newLabel],
          content: this.getProps(newLabel),
        });
      });
      if (evt.content) {
        api.evalXML(evt.content);
        if (showUserMarkers) {
          const {name, color} = evt.client.user;
          api.removeMultiuserSelections(evt.client.id);
          api.addMultiuserSelection(evt.client.id, name, color, evt.labels[0], true);
        }
      }
    }

    if (evt.eventType == "obj_change") {
      let pendingAck = false;
      this.expectedAcknowledgements.forEach((ack) => {
        pendingAck = pendingAck || !(evt.eventType == ack.eventType &&
          ack.label == evt.label && evt.property == ack.property);
      });

      if (pendingAck) {
        log.debug(`Acknowledgement pending for property ${evt.property} of ${evt.label}, event rejected`);
        return;
      }

      const {name, color} = evt.client.user;
      if (evt.property == "selection") {
        if (evt.label) {
          api.addMultiuserSelection(evt.client.id, name, color, evt.label, false);
        } else {
          api.removeMultiuserSelections(evt.client.id);
        }
      } else {
        let strokeMatch;
        if (evt.content?.startsWith("<")) {
          api.evalXML(evt.content);
        } else if (strokeMatch = evt.content?.match(/([\w{}]+):PenStroke\[([\w.,-]+)\]/)) {
          const coords: number[] = strokeMatch[2].split(",").map((n) => parseFloat(n));
          api.setCoords(strokeMatch[1], ...coords);
        } else if (evt.content) {
          api.evalCommand(evt.content);
        }
        if (showUserMarkers && evt.label) {
          api.addMultiuserSelection(evt.client.id, name, color, evt.label, true);
        }
        api.updateConstruction();
      }
    }

    if (evt.eventType == "rename") {
      this.api.renameObject(evt.label, evt.newLabel);
    }

    if (evt.eventType == "setting_change") {
      if (evt.properties.calculator) {
        api.switchCalculator(evt.properties.calculator);
      }
      if (evt.properties.animating === false) {
        api.stopAnimation();
      } else if (evt.properties.animating) {
        api.startAnimation();
      }
    }
    if (evt.eventType == "group") {
      if (evt.grouped) {
        api.groupObjects(evt.labels);
      } else {
        api.ungroupObjects(evt.labels);
      }
    }
    if (evt.eventType == "obj_delete" && evt.label) {
      this.deleteEmbedEventBus(evt.label);
      api.deleteObject(evt.label);
    }

    if (evt.eventType == "graphics_change") {
      let pendingAck = false;
      this.expectedAcknowledgements.forEach((ack) => {
        pendingAck = pendingAck || !(ack.eventType == "graphics_change");
      });

      if (pendingAck) {
        log.debug("Acknowledgement pending for graphics property, event rejected");
        return;
      }

      api.setGraphicsOptions(evt.viewId, evt.graphicsOptions);
    }

    if (evt.eventType == "image_add") {
      api.addImage(evt.fileName, evt.fileContent);
      api.updateConstruction();
    }

    if (evt.eventType == "order_change") {
      api.updateOrdering(evt.order);
    }
    if (evt.eventType == "remove_page" || evt.eventType == "clear_page") {
      api.handlePageAction(evt.eventType.replace("_p", "P"), evt.page);
      if (this.parent.storedState?.events) {
        this.parent.storedState.events = this.parent.storedState.events.filter((oldEvt) => oldEvt.page != evt.page);
      }
      if (this.parent.storedState?.states) {
        this.parent.storedState.states = this.parent.storedState.states.filter((state) => state.page != evt.page);
      }
    }
    if (evt.eventType == "move_page") {
      api.handlePageAction("movePage", evt.page, evt);
    }
    if (evt.eventType == "rename_page") {
      api.handlePageAction("renamePage", evt.page, evt);
    }
    if (evt.eventType == "add_page") {
      api.handlePageAction("addPage", evt.page);
    }
    if (evt.eventType == "paste_page") {
      api.handlePageAction("pastePage", evt.page, evt);
    }
  }

  send(evt: WorkEvent) {
    this.expectedAcknowledgements.add(evt);
    const labeledEvt: WorkEvent = {page: this.activePage, ...evt, embedLabel: this.embedLabel};
    this.parent.socket.emit("work", labeledEvt, (state: AckState) => {
      this.expectedAcknowledgements.delete(evt);
      if (state.ack) {
        log.debug("Event acknowledged", evt);
        this.parent.globalEventCounter++;
      } else if (!state.ack) {
        // TODO: handle rejection
        log.debug(`Event rejected${state.reason ? ` because ${state.reason}` : ""}`, evt);
      }
      this.parent.resolvePendingSnapshotRequest();
    });
  }

  getSnapshot(snapshots: CalculatorState[]) {
    for (const page of this.api.getPages()) {
      const content = this.api.getPageContent(page);
      snapshots.push({
        ...content,
        embedLabel: this.embedLabel,
        page: page,
      });
    }
  }

  stop() {
    this.addListener && this.api.unregisterAddListener(this.addListener);
    this.updateListener && this.api.unregisterUpdateListener(this.updateListener);
    this.renameListener && this.api.unregisterRenameListener(this.renameListener);
    this.removeListener && this.api.unregisterRemoveListener(this.removeListener);
    this.clientListener && this.api.unregisterClientListener(this.clientListener);
  }
}
