/**
 * Singleton logger for client. In principle it's possible that multiple Multiplayer instances
 * exist in a tab: if one of them enables logging, all of them will log to console.
 */
class Logger {
  enabled = false;
  debug(...args: unknown[]): void {
    // eslint-disable-next-line no-console
    this.enabled && console.log(...args);
  }
  error(...args: unknown[]): void {
    // eslint-disable-next-line no-console
    this.enabled && console.error(...args);
  }
  enable(): void {
    this.enabled = true;
  }
};

export default new Logger();
