import {
  ClientToServerEvents, CalculatorState,
  ServerToClientEvents, TerminateEvent,
  MultiuserState, JoinEvent, UserChangeArgs, User, WorkArgs, JoinState, AckState,
} from "../lib/types";
import {GeoGebraAPI} from "../lib/geogebra";
import {WorkEventBus} from "./event-bus";
import log from "./console-logger";
import {io, ManagerOptions, Socket, SocketOptions} from "socket.io-client";

const HTTP_STATUS_PAYLOAD_TOO_LARGE = 413;

interface Config {
  collabUrl: string;
  showLogging: boolean;
}

export type ConnectionChangeEvent =
  { connected: true } |
  {
    connected: false,
    reason: "payload too large" | Socket.DisconnectReason,
    tryReconnect: boolean
  }

type UserChangeListener = (users: User[]) => void;
type ConnectionChangeListener = (details: ConnectionChangeEvent) => void;

declare global {
  const config: Config;
  interface Window {
    GGBMultiplayer: (api: GeoGebraAPI, teamId: string, config?: Config, jwt?: string) => GGBMultiplayer;
  }
}

export class GGBMultiplayer {
  globalEventCounter = 0;
  snapshotRequested = false;

  api: GeoGebraAPI | undefined;

  socket: Socket<ServerToClientEvents, ClientToServerEvents>;

  private readonly userChangeListeners: UserChangeListener[];
  private readonly connectionChangeListeners: ConnectionChangeListener[];

  eventBuses: WorkEventBus[] = [];

  storedState: MultiuserState | undefined;
  teamId: string;

  constructor(api: GeoGebraAPI | undefined, teamId: string, userConfig?: Config, jwt?: string) {
    const effectiveConfig = Object.assign(config, userConfig);
    const options: Partial<ManagerOptions & SocketOptions> = {query: {teamId: teamId}};
    if (typeof jwt !== "undefined") {
      options.auth = {token: `Bearer ${jwt}`};
    }
    if (effectiveConfig.showLogging) {
      log.enable();
    }
    this.api = api;
    this.socket = io(effectiveConfig.collabUrl, options);
    this.userChangeListeners = [];
    this.connectionChangeListeners = [];
    this.teamId = teamId;
  }

  start(userName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const join: JoinEvent = {userName: userName, teamId: this.teamId, pages: this.api?.getPages() || []};

      this.socket.on("disconnect", (reason, error) => {
        const connected = false;
        const context: unknown = error && "context" in error && error.context;
        const payloadTooLarge = context instanceof XMLHttpRequest &&
            context.status === HTTP_STATUS_PAYLOAD_TOO_LARGE;

        if (payloadTooLarge) {
          this.socket.io.reconnectionAttempts(0);
          this.emitConnectionChange({connected, reason: "payload too large", tryReconnect: false});
        } else {
          this.emitConnectionChange({connected, reason, tryReconnect: true});
        }
      });

      this.socket.io.on("reconnect", () => {
        this.socket.emit("join", join, (joinState: JoinState) => {
          if (joinState.success) {
            // make sure the old event buses will not mess up anything
            for (const eventBus of this.eventBuses) {
              eventBus.stop();
              eventBus.blockOutgoingEvents = true;
            }

            // drop the existing event buses
            this.eventBuses = [];
            this.handleJoin(joinState.data);
          } else {
            throw new Error(`Failed to join during reconnect: ${joinState.error}`);
          }
        });
      });

      this.socket.on("node_change", (name) => {
        log.debug("Connected to pod", name);
      });

      this.setupSnapshotEventBus();
      this.setupUserEventBus();
      this.setupIncomingWorkEventBus();

      this.socket.emit("join", join, (joinState: JoinState) => {
        if (joinState.success) {
          this.handleJoin(joinState.data);
          resolve();
        } else {
          reject(new Error(`Failed to join: ${joinState.error}`));
        }
      });
    });
  }

  terminate() {
    const terminate: TerminateEvent = {teamId: this.teamId};
    this.socket.emit("terminate", terminate, (ack) => {
      if (ack) {
        this.disconnect();
        log.debug("Terminated");
      }
    });
  }

  handleJoin(multiuserState: MultiuserState) {
    log.debug("Joined. Current state of the session:", multiuserState);
    this.globalEventCounter = multiuserState.eventCounter;
    this.storedState = multiuserState;
    if (this.api) {
      this.initEventBus(undefined, this.api, false);
    }

    this.emitUserChange(multiuserState.users);
    this.emitConnectionChange({connected: true});
  }

  initEventBus(label: string | undefined, api: GeoGebraAPI, loadedWithFile: boolean) {
    if (!this.storedState || this.eventBuses.some((bus) => bus.embedLabel == label)) {
      return;
    }
    const workEventBus = new WorkEventBus(this, api, label);
    workEventBus.setupOutgoingEventBus();
    this.eventBuses.push(workEventBus);

    for (const image of this.storedState.images) {
      if (image.embedLabel == label) {
        api.addImage(image.fileName, image.fileContent);
      }
    }
    const state = this.storedState.states.find((s) => s.embedLabel == label);
    if (state) {
      workEventBus.blockOutgoingEvents = true;
      this.storedState.states.sort((a, b) => a.order - b.order);
      this.storedState.states.forEach((s) => {
        if (s.embedLabel == label) {
          api.setPageContent(s.page, s);
        }
      });
      for (const oldPage of api.getPages()) {
        if (!this.storedState.states.find((s) => s.page == oldPage)) {
          api.handlePageAction("removePage", oldPage);
        }
      }
      // removePage may have unblocked events
      workEventBus.blockOutgoingEvents = true;
      api.selectPage(api.getPages()[0]);
      workEventBus.activePage = api.getPages()[0];
      for (const evt of this.storedState.events) {
        workEventBus.checkAndHandleChangeEvent(evt, false);
      }
      this.storedState.events = this.storedState.events.filter((s) => s.embedLabel != label);
      // make sure we don't load the state again to another embed with the same label
      this.storedState.states = this.storedState.states.filter((s) => s.embedLabel != label);
      workEventBus.blockOutgoingEvents = false;
    } else if (loadedWithFile) {
      for (const obj of api.getAllObjectNames()) {
        workEventBus.transmitImageData(obj);
      }
      workEventBus.send({eventType: "content_change", xml: api.getXML()});
    }
  }

  setupIncomingWorkEventBus() {
    this.socket.on("work", (args: WorkArgs) => {
      for (const eventBus of this.eventBuses) {
        const handled = eventBus.checkAndHandleChangeEvent(args.event);
        if (handled) {
          return;
        }
      }

      log.debug("Unhandled event, saving for later", args.event);
      if (this.storedState) {
        this.storedState.events.push(args.event);
      }
    });
  }

  setupSnapshotEventBus() {
    this.socket.on("snapshot_req", () => {
      log.debug("Snapshot requested");
      this.snapshotRequested = true;
      this.resolvePendingSnapshotRequest();
    });
  }

  setupUserEventBus() {
    this.socket.on("user_change", (args: UserChangeArgs) => {
      this.emitUserChange(args.users);
    });
  }

  resolvePendingSnapshotRequest() {
    if (this.snapshotRequested && this.areAllEventsAcknowledged()) {
      const states: CalculatorState[] = [];
      this.eventBuses.forEach((bus) => bus.getSnapshot(states));
      const snapshot = {
        eventCounter: this.globalEventCounter,
        states,
      };
      this.socket.emit("snapshot", snapshot, (state: AckState) => {
        log.debug(state.ack ? "Snapshot accepted" : `Snapshot rejected by server: ${state.reason}`);
      });
      this.snapshotRequested = false;
    }
  }

  areAllEventsAcknowledged() {
    return this.eventBuses.every((bus) => !bus.expectedAcknowledgements.size);
  }

  addUserChangeListener(listener: UserChangeListener) {
    this.userChangeListeners.push(listener);
  }

  addConnectionChangeListener(listener: ConnectionChangeListener) {
    this.connectionChangeListeners.push(listener);
  }

  disconnect() {
    for (const eventBus of this.eventBuses) {
      eventBus.stop();
    }
    this.socket.disconnect();
  }

  private emitUserChange(users: User[]) {
    this.userChangeListeners.forEach((listener) => listener(users));
  }

  private emitConnectionChange(event: ConnectionChangeEvent) {
    this.connectionChangeListeners.forEach((listener) => listener(event));
  }
}

window.GGBMultiplayer = function(api: GeoGebraAPI, teamId: string, userConfig?: Config, jwt?: string) {
  return new GGBMultiplayer(api, teamId, userConfig, jwt);
};
